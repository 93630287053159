<template>
    <!-- <div class="my-plyr mx-1">
        <a :href="settings.telegram_chanel_url" target="_blank" class="">
            <v-img :class="['tg-banner mr-2']" src="@/assets/telegram_banner_pink_shadow.png"></v-img>
        </a>
    </div> -->
    <div v-if="currentTrack" id="nowPlayingBarContainer" class="rounded">

        <div id="nowPlayingBar">

            <div id="nowPlayingLeft">
                <div class="content container2">


                    <div class="albumLink px-2 pb-2">
                        <router-link :to="currentTrack.albums[0].path">
                            <v-img aspect-radio="1.0" :alt="currentTrack.albums[0].title" class=" rounded"
                                :src="currentTrack.thumbnail.link"></v-img>
                        </router-link>
                    </div>

                    <div id="trackInfo">

                        <div v-if="(currentTrack.title.length >= 12)" class="animated">
                            <router-link class="text-no-decoration" :to="currentTrack.albums[0].path">
                                <span class="trackTitle">{{ currentTrack.title }}</span>
                            </router-link>
                        </div>

                        <div v-else>
                            <router-link class="text-no-decoration" :to="currentTrack.albums[0].path">
                                <span class="trackTitle">{{ currentTrack.title }}</span>
                            </router-link>
                        </div>

                        <span class="overflow-hidden player-artists" style="display: inline-block;">
                            <span class="player-artists" v-for="(artist, index) in currentTrack.artists" :key="index">
                                <router-link class="text-no-decoration" style=" color: rgb(168, 168, 168);"
                                    :to="artist.path">
                                    {{ artist.name }}
                                </router-link>
                                <span v-if="typeof currentTrack.artists[index + 1] !== 'undefined'" class="d-inline"> ,
                                </span>
                            </span>
                        </span>

                    </div>



                </div>
            </div>

            <div id="nowPlayingCenter">

                <div class="buttons">
                    <button class="controlButton previous px-1">
                        <img aspect-ratio="1.0" :src="require('@/assets/icons/previous.png')" @click="previousTrack"
                            title="Previous Button" alt="Previous">
                    </button>

                    <button :class="['controlButton play px-1', { 'd-none': isPlaying }]">
                        <img :src="require('@/assets/icons/play.png')" @click="palyTrack" title="Play Button"
                            alt="Play">
                    </button>

                    <button :class="['controlButton pause px-1', { 'd-none': !isPlaying }]">
                        <img :src="require('@/assets/icons/pause.png')" @click="pauseTrack" title="pause Button"
                            alt="pause">
                    </button>

                    <button class="controlButton next px-1">
                        <img :src="require('@/assets/icons/next.png')" @click="nextTrack" title="Next Button"
                            alt="Next">
                    </button>
                </div>

            </div>
        </div>

        <div class="playBackBar pt-1 pb-4" style="z-index:20">
            <span class="progressTime current">{{ presenttime }}</span>
            <div ref="progressBar" class="progressBar" @mouseup="mouseUpHandler">
                <div class="progressBarBg" :style="{ 'width': timeProgress + '%' }"></div>
            </div>
            <span class="progressTime remaining">{{ getDuration }}</span>
        </div>
    </div>
</template>

<script>
import { timeFormat, timeProgress } from '@/helpers';
import debounce from 'lodash/debounce';


export default {

    data() {
        return {
            timeProgress: 0,
            valumeProgress: 100,
            presenttime: '0:00',
            muted: false
        }
    },

    computed: {

        settings() {
            return this.$store.getters.settings;
        },

        //get current track
        currentTrack() {
            return this.$store.getters.getCurrentTrack
        },

        //get current tracks
        currentTracks() {
            return this.$store.getters.getCurrentTracks
        },

        //track is playing or not
        isPlaying() {
            return this.$store.getters.getPlayerProps.isPlaying
        },

        //get current props of player
        playerProps() {
            return this.$store.getters.getPlayerProps
        },

        getCurrentIndex() {
            return this.$store.getters.getCurrentIndex
        },

        getDuration() {
            var duration = this.$store.getters.getPlayerProps.duration
            if (duration == 0) {
                return '0:00'
            } else {
                return timeFormat(duration)
            }
        }

    },

    async mounted() {
        var _this = this
        //init audio element
        var audioPlayer = this.playerProps.audioClass


        navigator.mediaSession.setActionHandler('nexttrack', () => {
            // Play next track.
            this.nextTrack()
        });

        navigator.mediaSession.setActionHandler('previoustrack', () => {
            // Play previous track.
            this.previousTrack()
        });

        navigator.mediaSession.setActionHandler('pause', () => {
            // Pause track.
            this.pauseTrack()
        });

        navigator.mediaSession.setActionHandler('stop', () => {
            // Pause track.
            this.pauseTrack()
        });

        navigator.mediaSession.setActionHandler('play', () => {
            // Play track.
            this.palyTrack()
        });

        navigator.mediaSession.setActionHandler('seekto', (event) => {
            // Handle the seekto action here
            // The event parameter contains information about the seek event
            // For example, you can use event.seekTime to get the seek time
            const seekTime = event.seekTime;
            // Perform the seeking operation in your audio player
            this.seekCurrentTime(seekTime)
        })

        //get audio duration
        audioPlayer.getAudio().addEventListener('loadedmetadata', () => {
            this.playerProps.duration = audioPlayer.getAudio().duration;

        });

        _this.totalTime = timeFormat(this.playerProps.duration)
        //update audio time
        audioPlayer.getAudio().addEventListener('timeupdate', () => {
            var currentTime = audioPlayer.getCurrentTime();
            _this.presenttime = timeFormat(currentTime)
            this.$store.commit('updateCurrentTime', currentTime);
            _this.timeProgress = timeProgress(currentTime, _this.playerProps.duration)
        });

        audioPlayer.getAudio().addEventListener("ended", function () {
            _this.nextTrack()
        });

    },

    methods: {

        async updateTrack(track, tracklist, play = false) {
            await this.$store.dispatch('setTrackUpdate', { trackObj: track, listObj: tracklist, play: play })
        },

        //play a track
        async palyTrack() {

            //check player src is empty or not. if it was empty , first set a src for player and then play audio
            if (!this.playerProps.src) {

                await this.updateTrack(this.currentTrack, this.currentTracks)
            }

            this.$store.commit('setPlayerPlay');

        },

        //pause a track
        pauseTrack() {
            this.$store.commit('setPlayerPause');
        },

        nextTrack: debounce(function () {
            this.pauseTrack()
            var trackList = this.currentTracks
            var currentIndex = this.getCurrentIndex
            if (currentIndex == trackList.length - 1) {
                currentIndex = 0
            } else {
                currentIndex++;
            }


            try {
                this.$store.commit('updateCurrentIndex', currentIndex);
                var trackObj = trackList[currentIndex];
                this.updateTrack(trackObj, trackList, true)
            } catch (error) {
                console.log('error')
            }
            //update current index
        }, 500),

        seekCurrentTime(time) {
            this.$store.commit('seekTime', time);
        },

        updateValume(value) {
            this.$store.commit('updateVolume', value);
        },

        updateMuted(value) {
            this.$store.commit('updateMuted', value);
        },

        previousTrack: debounce(function () {

            if (this.playerProps.currentTime >= 3 || this.getCurrentIndex == 0) {
                this.seekCurrentTime(0)
            } else {
                var trackList = this.currentTracks
                var currentIndex = this.getCurrentIndex
                currentIndex = currentIndex - 1;
                this.updateTrack(trackList[currentIndex], trackList, true)

            }
        }, 500),

        setTimeOffset(mouse, progressBar) {
            var precentage = mouse.offsetX / progressBar.offsetWidth * 100;
            var seconds = this.playerProps.duration * (precentage / 100);
            this.seekCurrentTime(seconds)
        },

        // mouseDownHandler(){
        //     this.mousedown=true
        // },

        // mouseMoveHandler(e){
        //     if(this.mousedown==true){
        //         this.setTimeOffset(e,this.$refs['progressBar'])
        //     }
        // },

        mouseUpHandler(e) {
            this.setTimeOffset(e, this.$refs['progressBar'])
            this.mousedown = false
        },

        setVolumeOffset(mouse, progressBar) {
            var precentage = mouse.offsetX / progressBar.offsetWidth;
            this.updateValume(precentage)
            this.valumeProgress = precentage * 100
        },

        // mouseVDownHandler(){
        //     this.mousedown=true
        // },

        // mouseVMoveHandler(e){
        //     if(this.mousedown==true){
        //         this.setVolumeOffset(e,this.$refs['progressvBar'])
        //     }
        // },

        mouseVUpHandler(e) {
            this.setVolumeOffset(e, this.$refs['progressvBar'])
            this.mousedown = false
        },

        muteTrack() {
            this.updateMuted(true)
            this.muted = true
        },

        unMuteTrack() {
            this.updateMuted(false)
            this.muted = false
        },
    }
};

</script>

<style>
.sticky-div {
    position: sticky;
    background-color: #121212;
    height: 65px;
    /* Set the height of your sticky div */
    z-index: 1;
    /* Ensure the sticky div is above the fixed menu */
    margin-bottom: 49px;
}
</style>
