<template>
    <div v-if="currentTrack" id="nowPlayingBarContainer" style="z-index: 2000;">
        <div id="nowPlayingBar">
            <div id="nowPlayingLeft">
                <div class="content">

                    <div class="albumLink">
                        <router-link :to="currentTrack.albums[0].path">
                            <img id="trart" :alt="currentTrack.albums[0].title" class="albumArtWork rounded"
                                :src="currentTrack.thumbnail.link">
                        </router-link>
                    </div>

                    <div id="trackInfo">


                        <div v-if="(currentTrack.title.length > 25)" class="animated">
                            <router-link class="text-no-decoration" :to="currentTrack.albums[0].path">
                                <span id="trtitle" class="trackTitle">{{ currentTrack.title }}</span>
                            </router-link>
                        </div>

                        <div v-else>
                            <router-link class="text-no-decoration" :to="currentTrack.albums[0].path">
                                <span id="trtitle" class="trackTitle">{{ currentTrack.title }}</span>
                            </router-link>
                        </div>


                        <span class="trackArtist overflow-hidden player-artists">
                            <span v-for="(artist, index) in currentTrack.artists" :key="index">
                                <router-link style="text-decoration: none; color: rgb(168, 168, 168);"
                                    :to="artist.path">
                                    {{ artist.name }}
                                </router-link>
                                <span v-if="typeof currentTrack.artists[index + 1] !== 'undefined'" class="d-inline"> ,
                                </span>
                            </span>
                        </span>

                    </div>

                </div>
            </div>

            <div id="nowPlayingCenter">
                <div class="buttons">
                    <button class="controlButton previous px-1">
                        <img :src="require('@/assets/icons/previous.png')" @click="previousTrack"
                            title="Previous Button" alt="Previous">
                    </button>

                    <button :class="['controlButton play px-1', { 'd-none': isPlaying }]">
                        <img :src="require('@/assets/icons/play.png')" @click="palyTrack" title="Play Button"
                            alt="Play">
                    </button>

                    <button :class="['controlButton pause px-1', { 'd-none': !isPlaying }]">
                        <img :src="require('@/assets/icons/pause.png')" @click="pauseTrack" title="Pause Button"
                            alt="Pause">
                    </button>

                    <button class="controlButton next px-1">
                        <img :src="require('@/assets/icons/next.png')" @click="nextTrack" title="Next Button"
                            alt="Next">
                    </button>
                </div>
                <div class="playBackBar">
                    <span class="progressTime current">{{ presenttime }}</span>
                    <div ref="progressBar" class="progressBar" @mousedown="mouseDownHandler"
                        @mousemove="mouseMoveHandler" @mouseup="mouseUpHandler">
                        <div class="progressBarBg" :style="{ 'width': timeProgress + '%' }"></div>
                    </div>
                    <span class="progressTime remaining">{{ getDuration }}</span>
                </div>
            </div>

            <div id="nowPlayingRight" v-if="!isMobile">
                <div class="volumeBar">
                    <button id='unmuteIcon' :class="['controlButton volume mt-2', { 'd-none': muted }]"
                        @click="muteTrack">
                        <img alt="unmute" :src="require('@/assets/icons/volume.png')">
                    </button>
                    <button id='muteIcon' :class="['controlButton volume mt-2', { 'd-none': !muted }]"
                        @click="unMuteTrack">
                        <img alt="mute" :src="require('@/assets/icons/volume-mute.png')">
                    </button>
                    <div ref="progressvBar" class="progressBar" @mousedown="mouseVDownHandler"
                        @mousemove="mouseVMoveHandler" @mouseup="mouseVUpHandler">
                        <div :style="{ 'width': valumeProgress + '%' }" class="progressBarBg"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { timeFormat, timeProgress, replaceChars } from '@/helpers';
import debounce from 'lodash/debounce';

export default {

    data() {
        return {
            timeProgress: 0,
            valumeProgress: 100,
            presenttime: '0:00',
            muted: false
        }
    },

    computed: {

        isMobile() {
            return this.$store.getters.getIsMobile;
        },

        //get current track
        currentTrack() {
            return this.$store.getters.getCurrentTrack
        },

        //get current tracks
        currentTracks() {
            return this.$store.getters.getCurrentTracks
        },

        //track is playing or not
        isPlaying() {
            return this.$store.getters.getPlayerProps.isPlaying
        },

        isTyping() {
            return this.$store.getters.getIsTyping
        },

        //get current props of player
        playerProps() {
            return this.$store.getters.getPlayerProps
        },

        getCurrentIndex() {
            return this.$store.getters.getCurrentIndex
        },

        getDuration() {
            var duration = this.$store.getters.getPlayerProps.duration
            if (duration == 0) {
                return '0:00'
            } else {
                return timeFormat(duration)
            }
        }

    },

    async mounted() {

        var _this = this
        //init audio element
        var audioPlayer = this.playerProps.audioClass

        //get audio duration
        audioPlayer.getAudio().addEventListener('loadedmetadata', () => {
            this.playerProps.duration = audioPlayer.getAudio().duration;

        });

        navigator.mediaSession.setActionHandler('nexttrack', () => {
            // Play next track.
            this.nextTrack()
        });

        navigator.mediaSession.setActionHandler('previoustrack', () => {
            // Play previous track.
            this.previousTrack()
        });

        navigator.mediaSession.setActionHandler('pause', () => {
            // Pause track.
            this.pauseTrack()
        });

        navigator.mediaSession.setActionHandler('play', () => {
            // Play track.
            this.palyTrack()
        });

        navigator.mediaSession.setActionHandler('seekto', (event) => {
            // Handle the seekto action here
            // The event parameter contains information about the seek event
            // For example, you can use event.seekTime to get the seek time
            const seekTime = event.seekTime;
            // Perform the seeking operation in your audio player
            this.seekCurrentTime(seekTime)
        })

        _this.totalTime = timeFormat(this.playerProps.duration)
        //update audio time
        audioPlayer.getAudio().addEventListener('timeupdate', () => {
            var currentTime = audioPlayer.getCurrentTime();
            _this.presenttime = timeFormat(currentTime)
            this.$store.commit('updateCurrentTime', currentTime);
            _this.timeProgress = timeProgress(currentTime, _this.playerProps.duration)
        });


        audioPlayer.getAudio().addEventListener("ended", function () {
            _this.nextTrack()
        });



        document.addEventListener('keydown', this.handleKeyDown);
    },

    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    },

    methods: {

        parseUrlChars(val) {
            return replaceChars(val)
        },

        handleKeyDown(event) {

            // Check if the pressed key is the spacebar (keyCode 32)

            if (event.keyCode === 32 && !this.isTyping) {
                event.preventDefault();

                if (this.isPlaying) {
                    this.pauseTrack()
                } else {
                    this.palyTrack()
                }
            }
        },



        updateTrack(track, tracklist, play = false) {
            this.$store.commit('setTrack', { trackObj: track, listObj: tracklist, play: play })
        },

        //play a track
        palyTrack() {

            //check player src is empty or not. if it was empty , first set a src for player and then play audio
            if (!this.playerProps.src) {

                this.updateTrack(this.currentTrack, this.currentTracks)
            }

            this.$store.commit('setPlayerPlay');
        },

        //pause a track
        pauseTrack() {
            this.$store.commit('setPlayerPause');
        },


        nextTrack: debounce(function () {
            this.pauseTrack()
            var trackList = this.currentTracks

            var currentIndex = this.getCurrentIndex
            if (currentIndex == trackList.length - 1) {
                currentIndex = 0
            } else {
                currentIndex += 1;
            }

            this.$store.dispatch('setUpdateCurrentIndex', currentIndex)

            var trackObj = trackList[currentIndex];
            this.updateTrack(trackObj, trackList, true)


            //update current index
        }, 800),

        seekCurrentTime(time) {
            this.$store.commit('seekTime', time);
        },

        updateValume(value) {
            this.$store.commit('updateVolume', value);
        },

        updateMuted(value) {
            this.$store.commit('updateMuted', value);
        },

        previousTrack() {

            if (this.playerProps.currentTime >= 3 || this.getCurrentIndex == 0) {
                this.seekCurrentTime(0)
            } else {
                var trackList = this.currentTracks
                var currentIndex = this.getCurrentIndex
                currentIndex = currentIndex - 1;
                this.updateTrack(trackList[currentIndex], trackList, true)

            }
        },

        setTimeOffset(mouse, progressBar) {
            var precentage = mouse.offsetX / progressBar.offsetWidth * 100;
            var seconds = this.playerProps.duration * (precentage / 100);
            this.seekCurrentTime(seconds)
        },

        mouseDownHandler() {
            this.mousedown = true
        },

        mouseMoveHandler(e) {
            if (this.mousedown == true) {
                this.setTimeOffset(e, this.$refs['progressBar'])
                this.mousedown = false
            }
        },

        mouseUpHandler(e) {
            this.setTimeOffset(e, this.$refs['progressBar'])
            this.mousedown = false
        },

        setVolumeOffset(mouse, progressBar) {
            var precentage = mouse.offsetX / progressBar.offsetWidth;
            this.updateValume(precentage)
            this.valumeProgress = precentage * 100
        },

        mouseVDownHandler() {
            this.mousedown = true
        },

        mouseVMoveHandler(e) {
            if (this.mousedown == true) {
                this.setVolumeOffset(e, this.$refs['progressvBar'])
                this.mousedown = false
            }

        },

        mouseVUpHandler(e) {
            this.setVolumeOffset(e, this.$refs['progressvBar'])
            this.mousedown = false
        },

        muteTrack() {
            this.updateMuted(true)
            this.muted = true
        },

        unMuteTrack() {
            this.updateMuted(false)
            this.muted = false
        },
    }
};

</script>

<style></style>
